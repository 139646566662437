/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import numeral from 'numeral';
import moment from 'moment';
import Tooltip from 'antd/lib/tooltip';
import Pagination from 'antd/lib/pagination';
import Popover from 'antd/lib/popover';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import { getTitle, paginate, httpRequest, baseURL, loanAPI, formatDate, rootURL } from '../config/constants';
import { doNotify } from '../actions/general';
import loading from '../assets/img/loading.gif';
import RemitaData from '../modals/RemitaData';
import CreditWallet from './CreditWallet';

const itemRender = (current, type, originalElement) => {
	if (type === 'prev') {
		return <a>Previous</a>;
	}
	if (type === 'next') {
		return <a>Next</a>;
	}
	return originalElement;
};
const pageSize = 5;

class LoanHistory extends Component {
	state = {
		currentPage: 1,
		totalPage: 0,
		loans: [],
		remitaInfo: null,
		modalIsOpen: false,
		exporting: false,
		export_id: null,
		loanID: null,
	};

	componentDidMount() {
		const { loans } = this.props;
		const filteredLoans = paginate(loans, pageSize, 1);
		this.setState({ totalPage: this.props.loans.length, loans: filteredLoans });
	}

	onChange = pageNumber => {
		const { loans } = this.props;
		const filteredLoans = paginate(loans, pageSize, pageNumber);
		this.setState({ currentPage: pageNumber, loans: filteredLoans });
	};

	exportHistory = async e => {
		try {
			e.preventDefault();
			const { user } = this.props;
			this.setState({ exporting: true });
			const url = `${baseURL}${loanAPI}/export/${user.id}?download=1`;
			const rs = await httpRequest(url, 'GET', true);
			if(rs.result) {
				this.notify('', rs.result, 'success');
				this.setState({ exporting: false });
			} else {
				this.setState({ exporting: false });
				this.notify('', 'loan history has been exported', 'success');
				window.open(rs.file, '_blank');
			}
		} catch(error) {
			this.setState({ exporting: false });
			const message = error.message || 'could not export history';
			this.notify('', message, 'error');
		}
	};

	exportLoan = async id => {
		try {
			this.setState({ exporting: true, export_id: id });
			const { user } = this.props;
			const url = `${baseURL}${loanAPI}/export/${user.id}?download=1&loan_id=${id}`;
			const rs = await httpRequest(url, 'GET', true);
			if(rs.result) {
				this.notify('', rs.result, 'success');
				this.setState({ exporting: false, export_id: null });
			} else {
				this.setState({ exporting: false, export_id: null });
				this.notify('', 'loan has been exported', 'success');
				window.open(rs.file, '_blank');
			}
		} catch(error) {
			this.setState({ exporting: false, export_id: null });
			const message = error.message || 'could not export loan';
			this.notify('', message, 'error');
		}
	};
    
    notify = (title, message, type) => {
		this.props.doNotify({
			message,
			level: type,
			title,
		});
	};
	
	showRemitaInfo = () => {
		const { current_loan } = this.props;
		if (current_loan.remita_user) {
			const data = JSON.parse(current_loan.remita_user.data);
			this.setState({ modalIsOpen: true, remitaInfo: data });
		} else {
			this.notify('', 'remita data not available', 'error');
		}
	}
	
	closeModal = () => {
		this.setState({ modalIsOpen: false, remitaInfo: null });
	}

	render() {
		const { currentPage, totalPage, loans, exporting, export_id, remitaInfo, modalIsOpen, loanID } = this.state;
		const { title, current_loan, user, updateWallet } = this.props;
		return (
			<div className="row">
				<div className="col-sm-12">
					<div className="element-actions">
						{current_loan && current_loan.platform === 'remita' && current_loan.remita_user && (
							<a className="btn btn-info btn-sm btn-export pointer" role="button" tabIndex="1" onClick={this.showRemitaInfo} style={{right:'162px'}}><span>Remita Data</span></a>
						)}
						{exporting ? (
							<a className="btn btn-primary btn-sm btn-export pointer"><img src={loading} alt=""/></a>
						) : (
							<a className="btn btn-primary btn-sm btn-export pointer" role="button" tabIndex="1" onClick={this.exportHistory}><span>Export History</span></a>
						)}
					</div>
					<h6 className="element-header">{title}</h6>
					<div className="table-responsive">
						<table className="table table-striped">
							<thead>
							<tr>
								<th>ID</th>
								<th>Description</th>
								<th>Amount</th>
								<th>Status</th>
								<th>Tenure</th>
								<th>Date Requested</th>
								<th>Date Approved</th>
								<th>Liquidated</th>
							</tr>
							</thead>
							<tbody>
								{loans.map((loan, i) => {
									const status = loan.deleted_by ? <Tooltip placement="top" title={getTitle('red', loan.decline_reason || '')}><div className="status-pill red"/></Tooltip> : (loan.approved === 1 ? (loan.disbursed === 1 ? <Tooltip placement="top" title={getTitle('green')}><div className="status-pill green"/></Tooltip>: <Tooltip placement="top" title={getTitle('yellow', 'not disbursed')}><div className="status-pill yellow"/></Tooltip>) : <Tooltip placement="top" title={getTitle('dark', 'not approved')}><div className="status-pill dark"/></Tooltip>);
									const remita = loan && loan.approved_remita ? JSON.parse(loan.approved_remita) : null;
									const mandateRef = remita ? remita.data.mandateReference : null;
									return (
										<tr key={i}>
											<td>
												{loan.id}<br/>
												<Popover
													title=""
													content={
														<CreditWallet
															doHide={() => this.setState({ loanID: null })}
															loan={loan}
															user={user}
															updateWallet={updateWallet}
														/>
													}
													trigger="click"
													visible={loanID && loanID === loan.id}
													onVisibleChange={(status) => this.setState({ loanID: status ? loan.id : null })}
												>
													<Tooltip title="credit wallet"><a className="text-primary cursor">
															<i className="os-icon os-icon-credit-card" />
														</a></Tooltip>
												</Popover>
												{loan.oletter && (
													<Tooltip title="download offer letter">
														<a className="text-info cursor ml-2" target="_blank" rel="noopener noreferrer" href={`${rootURL}/oletter/${loan.oletter}`}>
															<i className="os-icon os-icon-download-cloud" />
														</a>
													</Tooltip>
												)}
											</td>
											<td>
												{`${current_loan && current_loan.id === loan.id ? (loan.is_topup === 1 ? 'Current Topup-Loan' : 'Current Loan') : (loan.is_topup === 1 ? 'Topup-Loan' : 'Loan')} Request`}
												<br/><span className="bold smaller">{`Lender: ${loan.lender ? loan.lender.name : '-'}`}</span>
												{mandateRef && <br/>}
												{mandateRef && (
													<span className="bold smaller">{`Man. Ref: ${mandateRef || '-'}`}</span>
												)}
											</td>
											<td>
												{`₦${numeral(loan.amount).format('0,0.00')}`}<br/>
												<span className="bold smaller">{`source: ${loan.platform}`}</span>
												{mandateRef && <br/>}
												{mandateRef && (
													<span className="bold smaller">{`Auth Code: ${loan.auth_code || '-'}`}</span>
												)}
											</td>
											<td className="text-center">{status}</td>
											<td className="text-center">{loan.tenure}<br/>mnths</td>
											<td nowrap="nowrap">{moment(loan.created_at).format('DD-MMM-YYYY')}</td>
											<td nowrap="nowrap">{loan.approved_at ? moment(loan.approved_at).format('DD-MMM-YYYY') : '-'}</td>
											<td nowrap="nowrap">
												<Tooltip placement="top" title={loan.deleted_at ? `declined on ${formatDate(loan.deleted_at)}` : (loan.liquidate_approve === 1 && loan.liquidated === 1 ? 'Liquidated' : '')}>
													{loan.deleted_at ? '' : <a className={`badge badge-${loan.liquidate_approve === 1 ? 'success' : 'info'}-inverted badge-light`}>{loan.liquidate_approve === 1 ? 'YES' : 'NO'}</a>}
												</Tooltip>
												<Tooltip placement="top" title="Export Loan">
													{exporting && Number(loan.id) === Number(export_id) ? (
														<a className="btn btn-primary btn-sm ml-1"><img src={loading} alt=""/></a>
													) : (
														<a className="btn btn-primary btn-sm pointer ml-1" role="button" tabIndex="1" onClick={()=>this.exportLoan(loan.id)}><i className="os-icon os-icon-download-cloud mr-0"></i></a>
													)}
												</Tooltip>
											</td>
										</tr>
									)
								})}
								{totalPage === 0 && (
									<tr><td colSpan="8">No loans taken</td></tr>
								)}
							</tbody>
						</table>
					</div>
					<div className="pagination pagination-center mt-4">
						<Pagination
							current={currentPage}
							pageSize={pageSize}
							total={totalPage}
							showTotal={(total) => `Total ${total} loans`}
							itemRender={itemRender}
							onChange={this.onChange}
						/>
					</div>
				</div>

				<Modal
					isOpen={modalIsOpen}
					onRequestClose={this.closeModal}
					contentLabel="Remita Data"
					shouldCloseOnOverlayClick={false}
					overlayClassName="modal-dialog modal-lg"
					className="modal-content"
					portalClassName={`${modalIsOpen ? 'ReactModalPortalrd overlay-z' : ''}`}
					bodyOpenClassName="ReactModal__Body--openrd"
				>
					<RemitaData
						data={remitaInfo?.data}
						closeModal={() => this.closeModal()}
					/>
				</Modal>
			</div>
		);
	}
}

export default connect(null, { doNotify })(LoanHistory);
